@value variables: "../../styles/variables.module.css";
@value primary, light, font-regular from variables;

.buttonWrapper {
  position: absolute;
  display: flex;
  margin: 20px;
  color: white;
}

.buttonWrapper svg {
  margin-top: 1px;
}

.text {
  display: inline-block;
  padding-left: 13px;
  font-size: font-small;
  line-height: font-small;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
}


